
import {Vue, Component} from 'vue-property-decorator';
import AktarDetayUstBasliklar from "@/components/infos/raporlama-ofispro/helper-comps/AktarDetayUstBasliklar.vue";

@Component({
  components: {AktarDetayUstBasliklar}
})
export default class AktarilanDosyaDetay extends Vue {

}
