
import {Vue, Component, Mixins} from 'vue-property-decorator';
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({})
export default class AktarDetayUstBasliklar extends Mixins(ObjectInputMixin) {
  tabs = 0;


  buttons = [
    {
      label: "Tümü",
      route: "/ofispro/aktar-detay/" + this.$route.params.id,
    },
    /*{
      label: "Aktarılan Dosyalar",
      route: "/ofispro/aktarilan-detay/" + this.$route.params.id,

    },
    {
      label: "Aktarılmayan Dosyalar",
      route: "/ofispro/aktarilmayan-detay/" + this.$route.params.id,
    },*/
  ];

  mounted() {
    this.setActiveTab();

  }

  setActiveTab() {
    const currentRoute = this.$route.path;

    for (let i = 0; i < this.buttons.length; i++) {
      const button = this.buttons[i];

      if (button.route && currentRoute.startsWith(button.route)) {
        this.tabs = i;
        return;
      }
    }

  }

  isButtonActive(button) {
    const currentRoute = this.$route.path;

    if (button.route && currentRoute === button.route) {
      return true;
    }


    return false;
  }

  handleButtonClick(button) {
    if (button.route) {
      this.$router.push(button.route);
    }
  }
}
